import React, { PropsWithChildren, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToast, clearToasts, deleteToast } from '@reducers/toast-reducer';
import Type from '@ctypes/toast';
import ToastList from '@components/ui/toast-list';
import { toastSelector } from '@reducers/toast-reducer';

export interface ContextType {
    success(message: string, duration?: number): number;
    warning(message: string, duration?: number): number;
    info(message: string, duration?: number): number;
    error(message: string, duration?: number): number;
    remove(id: number): void;
    clear(): void;
}
export type ToastContextType = ContextType | null;

export const ToastContext = createContext<ToastContextType>(null);

export const ToastContextProvider = ({ children }: PropsWithChildren): React.ReactElement => {
    const dispatch = useDispatch();
    const { toasts } = useSelector(toastSelector);
    // Adding new toast to field list
    const add = (type: Type, message: string, duration?: number): number => {
        const id = Math.floor(Math.random() * 10000000);
        dispatch(addToast({ type, message, id, duration: duration || 4000, date: new Date().toISOString() }));
        return id;
    };
    // Remove existing toast by his id
    const remove = (id: number) => {
        dispatch(deleteToast(id));
    };
    // Publish successfull message
    const success = (message: string, duration?: number) => {
        return add('success', message, duration);
    };
    // Publish warning message
    const warning = (message: string, duration?: number) => {
        return add('warning', message, duration);
    };
    // Publish information message
    const info = (message: string, duration?: number) => {
        return add('info', message, duration);
    };
    // Publish message when errors
    const error = (message: string, duration?: number) => {
        return add('error', message, duration);
    };
    // Publish message when errors
    const clear = () => {
        dispatch(clearToasts());
    };

    const value = { success, warning, info, error, remove, clear };

    return (
        <ToastContext.Provider value={value}>
            <ToastList toasts={toasts} onClose={remove} position="bottom-right" />
            {children}
        </ToastContext.Provider>
    );
};
