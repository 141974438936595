import React, { useEffect, useState } from 'react';
import { FormDataState } from '@reducers/form-reducer';
import { IconName } from '@components/ui/svg-icon';
import './select.css';
import Select from 'react-select';
import Icon from '../icon';
type SelectType = 'lang';

export interface Props {
    id: string;
    blackTheme?: boolean;
    type?: SelectType;
    icon?: IconName;
    name: string;
    placeholder?: string;
    label?: string;
    valueKey?: string;
    required?: boolean;
    options: any[];
    value?: any;
    parent?: FormDataState;
    onChange?(value: string | boolean | number | undefined): void;
    disabled?: boolean;
    formatOptionLabel?(option: any): any;
    hideLabel?: boolean;
    invalid?: boolean;
}

export type Ref = HTMLInputElement;
// Notice here we're using the function declaration with the interface Props
const SelectInput: React.FunctionComponent<Props> = (props) => {
    const {
        id,
        name,
        placeholder,
        blackTheme,
        type,
        label,
        required,
        valueKey,
        formatOptionLabel,
        onChange,
        value,
        parent,
        disabled = false,
        options,
        hideLabel,
        icon,
        invalid,
    } = props;
    //Creating compoent state
    const [error, setError] = useState('');
    const [focused, setFocused] = useState(false);
    const [inputIconClass, setInputIconClass] = useState('');
    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            width: '100%',
        }),
        control: (baseStyles: any) => ({
            ...baseStyles,
            padding: '6px',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderToprightRadius: '0',
            borderBottomRightRadius: '0',
            borderColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
        }),
    };

    useEffect(() => {
        let cz = 'icon-input';
        if (focused) {
            cz += ' focused';
        }
        if (invalid) {
            cz += ' invalid';
        }
        setInputIconClass(cz);
    }, [focused]);

    const inputHandler = (value: any) => {
        if (onChange) {
            onChange(getValue(value));
        }
    };

    // Initialize the input field with the default value
    useEffect(() => {
        document.addEventListener('click', (event: any) => {
            if (event.target.id === id || event.target.classList.contains(id + '__input-container')) {
                setFocused(true);
            } else {
                setFocused(false);
            }
        });
    }, []);

    useEffect(() => {
        if (parent && parent.inputs[name] && parent.inputs[name].errors !== undefined) {
            const errors = parent.inputs[name].errors;
            if (errors && errors.hasError) {
                setError(errors.first ?? '');
                return;
            }
        }
        setError('');
    }, [parent]);

    const customFormatOptionLabel = (option: any) => {
        if (type === 'lang' && !formatOptionLabel) {
            return (
                <div className="d-flex align-items-center gap-1 item-select">
                    <span
                        className={`flag ${option?.code?.toLowerCase()} d-inline-block`}
                        style={{ height: 20, width: 20 }}
                    ></span>{' '}
                    <span className="select-field-name">{option?.label}</span>
                </div>
            );
        }
        if (formatOptionLabel) {
            return formatOptionLabel(option);
        }
        return <></>;
    };

    const handleSelect = (newValue: any) => {
        inputHandler(newValue);
    };

    const getValue = (val: any) => {
        console.log('vvvvvvvvvvvvvvvvvvvvvvv:', val);
        if (valueKey && typeof val === 'object' && val.hasOwnProperty(valueKey)) {
            return val[valueKey];
        }
        return val;
    };

    const getDefaultValue = () => {
        if (valueKey && typeof value === 'object' && value.hasOwnProperty(valueKey)) {
            return options.find((item) => item[valueKey] === value[valueKey]);
        } else if (valueKey && (typeof value === 'number' || typeof value === 'string')) {
            return options.find((item) => item[valueKey] === value);
        }
        return value;
    };

    const getInputValue = () => {
        if (valueKey) {
            return options.find((item) => item[valueKey] === value);
        }
        return value;
    };

    const getOptionValue = (option: any) => {
        if (valueKey && typeof option === 'object' && option.hasOwnProperty(valueKey)) {
            return option[valueKey];
        }
        return option;
    };

    return (
        <fieldset className={`input-field ${blackTheme ? 'black-theme' : ''}`}>
            {!hideLabel && (
                <label htmlFor={id}>
                    {label} {(required === undefined || required) && <span className="form-require">*</span>}
                </label>
            )}
            <div
                className={`input-select ${inputIconClass} ${focused ? 'focused' : ''} ${
                    error && invalid ? 'invalid' : ''
                }`}
            >
                {icon && <Icon name={icon}></Icon>}
                <Select
                    defaultValue={getDefaultValue()}
                    onChange={handleSelect}
                    placeholder={placeholder}
                    options={options}
                    className="fav-select"
                    styles={customStyles}
                    classNamePrefix="fabfab_select"
                    getOptionValue={(option: any) => getOptionValue(option)}
                    getOptionLabel={(option: any) => option.label}
                    formatOptionLabel={customFormatOptionLabel}
                    value={getInputValue()}
                    isDisabled={disabled}
                    id={id}
                    name={name}
                    onMenuOpen={() => setFocused(true)}
                    onMenuClose={() => setFocused(false)}
                />
            </div>

            {error && invalid && <strong className="invalid">{error}</strong>}
        </fieldset>
    );
};
SelectInput.displayName = 'SelectInput';

export default SelectInput;
