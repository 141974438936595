import { d } from '@utils/encryption-wrapper';
import { useState } from 'react';
import { Alert } from '@utils/alert';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';

export const useWithdrawal = () => {
    const [feedbackReceived, setFeedbackReceived] = useState<boolean>(false);
    const { hideModal } = useModal();

    const onWithdrawalFeedback = (data: string) => {
        if (!feedbackReceived) {
            setFeedbackReceived(true);
            const feedBackData: any = d(data);
            Alert.success(feedBackData.title, feedBackData.message, null, { cancelable: false });
        }
        hideModal(MODAL_TYPES.WITHDRAWAL_MODAL);
    };

    return {
        onWithdrawalFeedback,
        setFeedbackReceived,
        feedbackReceived,
    };
};
