import React from 'react';
import './share-modal.css';
import Modal from '@components/ui/modal';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useToast } from '@hooks/use-toast';
import { useTranslation } from 'react-i18next';
// Notice here we're using the function declaration with the interface Props
export interface ShareModalProps {
    imageUrl: string;
    url: string;
    title: string;
}

const ShareModal: React.FunctionComponent<ShareModalProps> = ({ imageUrl, url, title }: ShareModalProps) => {
    const { hideModal } = useModal();
    const toast = useToast();
    const { t } = useTranslation(['messages']);
    const handleCloseModal = () => {
        hideModal(MODAL_TYPES.SHARE_MODAL);
    };

    const copyUrlToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            toast?.success(t('shared_link_copied'));
        } catch (err) {
            toast?.success(t('shared_link_failed'));
            console.error('Failed to copy text to clipboard:', err);
        }
    };

    return (
        <Modal show={true} onClose={handleCloseModal} id="loser-modal" title="">
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={imageUrl} alt="" width={'100%'} />
                </div>
                <div className="d-flex gap-2">
                    <FacebookShareButton url={url} title={title}>
                        <FacebookIcon round={true} size={45} />
                    </FacebookShareButton>
                    <TwitterShareButton url={url} title={title}>
                        <TwitterIcon round={true} size={45} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={url} title={title}>
                        <WhatsappIcon round={true} size={45} />
                    </WhatsappShareButton>
                    <span className="pointer" onClick={copyUrlToClipboard}>
                        <svg width="50" height="50" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 7H11C10.4239 7 10 7.42386 10 8V10H8C7.42386 10 7 10.4239 7 11V16C7 16.5761 7.42386 17 8 17H13C13.5761 17 14 16.5761 14 16V14H16C16.5761 14 17 13.5761 17 13V8C17 7.42386 16.5761 7 16 7ZM8 11H10V13C10 13.5761 10.4239 14 11 14H13V16H8V11ZM11 13V8H16V13H11Z"
                                fill="#FFF"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                fill="none"
                            />
                        </svg>
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
