import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import './mobile-menu.css';
import { Link } from 'react-router-dom';
import { useAuth } from '@hooks/use-auth';
// Notice here we're using the function declaration with the interface Props
type Props = {
    opened?: boolean;
};

const MobileMenu: React.FunctionComponent<Props> = ({ opened = false }: Props) => {
    const { t, i18n } = useTranslation();
    const language = Cookies.get('i18next') || 'fr';
    const { authed } = useAuth();

    const onClickLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        Cookies.set('i18next', language);
    };

    return (
        <div className={`menu-link web-hide ${opened ? 'opened' : ''}`}>
            <div className="menu-content">
                <ul className="nav-link">
                    <h4>Menu</h4>
                    <li>
                        <Link to="/playing">{t('Comment jouer')}</Link>
                    </li>
                    <li>
                        <a href="#">{t('Télécharger sur mobile')}</a>
                    </li>
                    <li>
                        <a href="#">{t('Mode de paiement')}</a>
                    </li>
                </ul>
                {!authed && (
                    <div className="app-link">
                        <h4>{t("Acceder à l'application")}</h4>
                        <Link className="button style-link align-items-center justify-content-center" to="/auth/signup">
                            {t('Inscription')}
                        </Link>
                        <Link className="button default" to="/auth/signin">
                            {t('Connexion')}
                            <span className="icon user-check"></span>
                        </Link>
                    </div>
                )}

                <div className="d-flex language-container">
                    <span
                        onClick={() => onClickLanguageChange('fr')}
                        className={`language button default d-flex justify-content-center align-items-center gap-1 ${
                            language == 'fr' ? 'active' : ''
                        }`}
                    >
                        <i className="flag fr" style={{ height: 20, width: 20, display: 'inline-block' }}></i>FR
                    </span>
                    <span
                        onClick={() => onClickLanguageChange('en')}
                        className={`language button default d-flex justify-content-center align-items-center gap-1 ${
                            language == 'en' ? 'active' : ''
                        }`}
                    >
                        <i className="flag gb" style={{ height: 20, width: 20, display: 'inline-block' }}></i>EN
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
