import React, { PropsWithChildren, createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTournaments } from '@redux/reducers/tournament-reducer';
import { useAuth } from '@hooks/use-auth';

export type InitAppContextType = {
    //
};

export const InitAppContext = createContext<InitAppContextType>({} as InitAppContextType);

export const InitAppContextProvider = ({ children }: PropsWithChildren): React.ReactElement => {
    const dispatch = useDispatch();
    const { authed } = useAuth();

    useEffect(() => {
        if (authed && location.pathname != '/apps/games/competitions') {
            dispatch(getTournaments(1));
        }
    }, [authed]);

    const value = {};

    return <InitAppContext.Provider value={value}>{children}</InitAppContext.Provider>;
};
