import Spinner from '@components/ui/spinner';
import tournamentService from '@services/tournament-service';
import Leaderboard, { LeaderboardUser } from 'interfaces/leaderboard';
import { Tournament } from 'interfaces/tournament.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './tournament-classification.css';
import { useTournament } from '@hooks/use-tournament';
export type TournamentClassificationProps = {
    tournament: Tournament;
};
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';

function TournamentClassification(
    props: TournamentClassificationProps,
): React.ReactElement<TournamentClassificationProps> {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [leaderboard, setLeaderboard] = useState<Leaderboard>();
    const [leaderboardUsers, setLeaderboardUsers] = useState<LeaderboardUser[]>([]);
    const { findCurrentRound } = useTournament();
    const { tournament } = props;
    const { t } = useTranslation(['competition']);

    const fetchLeaderboard = () => {
        setIsLoading(true);
        tournamentService
            .getActiveLeaderboard()
            .then((leaderboard) => {
                setLeaderboard(leaderboard);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchLeaderboard();
    }, []);

    useEffect(() => {
        if (leaderboard?.tournament.id == tournament._id) {
            setLeaderboardUsers(leaderboard.users);
        } else {
            setLeaderboardUsers([]);
        }
    }, [leaderboard]);

    useEffect(() => {
        if (!tournament) {
            return;
        }
        const currentRound = findCurrentRound(tournament.automation.rounds);
        // Declare variables with proper types
        let interval: NodeJS.Timeout;
        let timeout: NodeJS.Timeout;

        const clearAll = () => {
            if (interval) {
                clearInterval(interval);
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        };

        if (currentRound) {
            // Run the process every two minute (120000ms)
            interval = setInterval(() => {
                fetchLeaderboard();
            }, 120000);

            // Stop the process after 1 hour (3600000ms)
            timeout = setTimeout(() => {
                clearInterval(interval);
                console.log('Stopped process after 1 hour.');
            }, 3600000);
        } else {
            clearAll();
        }

        // Cleanup the intervals and timeouts on component unmount
        return () => {
            clearAll();
        };
    }, [tournament]);

    return (
        <div className="leaderboard-list">
            <div className="d-flex align-items-end justify-content-end">
                <button
                    className="w-auto success text-center d-flex justify-content-center "
                    onClick={fetchLeaderboard}
                >
                    <span className="icon icon12">
                        <RefreshIcon />
                    </span>
                </button>
            </div>
            {leaderboardUsers.length > 0 && (
                <>
                    {leaderboardUsers.map((user: LeaderboardUser, index: number) => {
                        return (
                            <div className="leadboard-player-infos w-100 opaced" key={index}>
                                <span className="number">{index + 1}</span>
                                <div className="payer-infos-score">
                                    <div className="player-pseudo">
                                        <div className="user-avatar">
                                            <img src={defaultAvatar} alt="" />
                                        </div>
                                        <span className="user-name">
                                            <b>{user.pseudo}</b>
                                        </span>
                                    </div>
                                    <div className="player-separator"></div>
                                    <span className="score">{user.score} pt(s)</span>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {(isLoading || leaderboardUsers.length == 0) && (
                <div className="page-section mobile-padding-security mt-4 w-100">
                    <div className="d-flex align-items-center widget info-card opaced create-game-card  text-center w-100">
                        <p className="text-center">{t('no_leader_board')}</p>
                        {isLoading && (
                            <span className="flex-none" style={{ width: 100 }}>
                                <Spinner color="#FFF" />
                            </span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default TournamentClassification;
