import React, { useReducer, useEffect, useState } from 'react';
import modalReducer from '@reducers/modal-reducer';
import { showModal } from '@redux/reducers/modal-reducer';
import './modal.css';
import { useModal } from '@contexts/global-modal-context';

export type ModalProps = {
    children?: React.ReactNode;
    show: boolean;
    hideHeader?: boolean;
    onClose?(): void;
    onOpen?(): void;
    title: string;
    id: string;
    size?: string;
    hideOnAreaClick?: boolean;
    className?: string;
    showCloseButton?: boolean;
    showTransition?: boolean;
    hideOnClose?: boolean;
};

const Modal: React.FunctionComponent<ModalProps> = ({
    id = Math.random() * 1000 + '',
    title = '',
    children,
    show = false,
    showCloseButton = true,
    hideHeader = false,
    hideOnAreaClick = true,
    showTransition = true,
    hideOnClose = true,
    size,
    onClose,
    onOpen,
    className,
}: ModalProps) => {
    const [modalState, dispatch] = useReducer(modalReducer, { id: id, opened: show });
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { opened } = modalState;
    const { hideModal } = useModal();

    const handleOverlayClick = () => {
        if (opened && hideOnAreaClick) {
            handleCloseModal();
        }
    };
    const handleCloseModal = () => {
        if (opened) {
            onClose ? onClose() : {};
            if (hideOnClose === true) {
                dispatch(showModal({ id, show: !opened }));
                hideModal();
            }
        }
    };

    useEffect(() => {
        dispatch(showModal({ id, show }));
    }, []);

    useEffect(() => {
        dispatch(showModal({ id, show }));
    }, [show]);

    useEffect(() => {
        if (opened) {
            onOpen ? onOpen() : {};
        } else {
            onClose ? onClose() : {};
        }
        setIsOpened(!!opened);
    }, [opened, showTransition]);

    if (!opened) {
        return null;
    }

    return (
        <div className={'overlay' + ((opened && !showTransition) || (showTransition && isOpened) ? ' opened' : '')}>
            <div className="overlay-cache" onClick={handleOverlayClick}></div>
            <div
                className={`modal modal-one ${
                    (opened && !showTransition) || (showTransition && isOpened) ? ' active-modal' : ''
                } ${className} ${size}`}
                id={id}
            >
                {!hideHeader && (
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                        {showCloseButton && (
                            <div className="modal-close" onClick={handleCloseModal}>
                                <span className="icon icon12">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292893L15.7071 1.70711ZM0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683417 16.0976 1.31658 16.0976 1.70711 15.7071L0.292893 14.2929ZM1.70712 0.292893C1.3166 -0.0976311 0.683432 -0.0976311 0.292907 0.292893C-0.097617 0.683418 -0.097617 1.31658 0.292907 1.70711L1.70712 0.292893ZM14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0977 15.3166 16.0977 14.6834 15.7071 14.2929L14.2929 15.7071ZM14.2929 0.292893L0.292893 14.2929L1.70711 15.7071L15.7071 1.70711L14.2929 0.292893ZM0.292907 1.70711L14.2929 15.7071L15.7071 14.2929L1.70712 0.292893L0.292907 1.70711Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                            </div>
                        )}
                    </div>
                )}

                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
