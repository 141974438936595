import React, { useEffect, useState } from 'react';
import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import './infos-modal.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Colors from '../../constants/colors';
import { Information } from '@models/informations.model';
import { __INFOS__ } from '@constants/storage';
import { useLocalStorage } from '@hooks/use-local-storage';
import infosService from '@services/infos-service';
import { useInformations } from '@hooks/use-information';

type LanguateType = 'fr' | 'en';

type TipsProps = {
    infos: Information[];
};
function InfosModal(props: TipsProps): React.ReactElement<TipsProps> {
    const { hideModal } = useModal();
    const { t } = useTranslation(['infos-modal']);
    const [index, setIndex] = useState<number>(0);
    const language: LanguateType = (Cookies.get('i18next') as LanguateType) || 'fr';
    const [, setHasAlreadySeenInfos] = useLocalStorage(__INFOS__, true);
    const { infos } = props;
    const { onViewInfos } = useInformations();

    const handleClose = () => {
        hideModal();
    };

    const hasNext = () => {
        return index < infos.length - 1;
    };

    const hasPrevious = () => {
        return index > 0;
    };

    const handleNext = () => {
        setIndex(Math.min(index + 1, infos.length - 1));
    };

    const handlePrevious = () => {
        setIndex(Math.max(index - 1, 0));
    };

    const onSubmit = async () => {
        await infosService.viewInfo(infos.map((info) => info.id));
        onViewInfos();
        handleClose();
    };

    useEffect(() => {
        setHasAlreadySeenInfos(true);
    });

    return (
        <Modal
            show={true}
            onClose={onSubmit}
            id="party-modal"
            title={t('title', { index: index + 1, total: infos.length })}
        >
            <div className="form-container infos">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className={`col-12 tab active`} id="tab">
                            <h4 className="d-flex justify-content-center align-items-center flex-column">
                                <b>****</b>
                                <b>{infos[index].title[language]}</b>
                                <b>****</b>
                            </h4>
                            <div className="document-side d-flex align-items-center justify-content-center">
                                <p
                                    className="text-center infos-content"
                                    dangerouslySetInnerHTML={{
                                        __html: infos[index].message[language],
                                    }}
                                ></p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-2 mb-3">
                        <strong style={{ color: Colors.primaryColor }}>
                            {index < 9 ? '0' : ''} {index + 1}
                        </strong>
                        <strong className="text-white">/ {infos.length}</strong>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center d-flex">
                        {hasPrevious() && (
                            <button className="button danger" onClick={handlePrevious}>
                                {t('previous', { ns: 'actions' })}
                            </button>
                        )}

                        {hasNext() && (
                            <button className="button default" onClick={handleNext}>
                                {t('next', { ns: 'actions' })}
                            </button>
                        )}

                        {!hasNext() && (
                            <button className="button default" onClick={onSubmit}>
                                {t('close', { ns: 'actions' })}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default InfosModal;
