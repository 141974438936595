import React, { useEffect, useRef } from 'react';
import './toast-list.css';
import Toast from '@components/ui/toast';
import _Toast from '@models/toast';

type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

interface Props {
    position: Position;
    toasts: _Toast[];
    onClose(id: number): void;
}

const ToastList: React.FunctionComponent<Props> = ({ toasts, position, onClose }: Props) => {
    const listRef = useRef(null);

    const handleScrolling = (el: any) => {
        const isTopPosition = ['top-left', 'top-right'].includes(position);
        if (isTopPosition) {
            el?.scrollTo(0, el.scrollHeight);
        } else {
            el?.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        handleScrolling(listRef.current);
    }, [position, toasts]);

    const sortedData = position.includes('bottom') ? [...toasts].reverse() : [...toasts];

    if (sortedData.length > 0) {
        return (
            <div className={`toast-list toast-list--${position}`} aria-live="assertive" ref={listRef}>
                {sortedData.map((toast: _Toast) => (
                    <Toast
                        key={toast.id}
                        message={toast.message}
                        type={toast.type}
                        id={toast.id}
                        duration={toast.duration}
                        date={new Date(toast.date)}
                        onClose={(id: number) => onClose(id)}
                    />
                ))}
            </div>
        );
    }
    return <></>;
};
export default ToastList;
