import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux/es/exports';
import { store } from '@redux/store';
import { ToastContextProvider } from '@contexts/toast-context';
import { AxiosInterceptorContextProvider } from '@contexts/axios-interceptor-context';
import { AuthProvider } from '@contexts/auth-context';
import { ModalContextProvider } from '@contexts/global-modal-context';
import initToastMessage from '@utils/toast-message';
import { SocketContextProvider } from '@contexts/socket-context';
import { SideBarContextProvider } from '@contexts/sidebar-context';
//import * as serviceWorker from './service-worker';
import * as serviceWorker from './service-worker';
import { InitAppContextProvider } from '@contexts/init-context';

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
initToastMessage(store.dispatch);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <AuthProvider>
                    <ToastContextProvider>
                        <SocketContextProvider>
                            <ModalContextProvider>
                                <AxiosInterceptorContextProvider>
                                    <SideBarContextProvider>
                                        <InitAppContextProvider>
                                            <App />
                                        </InitAppContextProvider>
                                    </SideBarContextProvider>
                                </AxiosInterceptorContextProvider>
                            </ModalContextProvider>
                        </SocketContextProvider>
                    </ToastContextProvider>
                </AuthProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
