import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _Toast from '@models/toast';
import moment from 'moment';

export interface ToastState {
    toasts: _Toast[];
}

const initialState: ToastState = {
    toasts: [],
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        clearToasts: (state) => {
            state.toasts = [];
        },
        addToast: (state, { payload }: PayloadAction<_Toast>) => {
            const now = moment();
            state.toasts = [...state.toasts, payload].filter((toast) => {
                const expirationTime = moment(toast.date).add(toast.duration, 'milliseconds');
                return now.isBefore(expirationTime);
            });
        },

        deleteToast: (state, { payload }: PayloadAction<number>) => {
            const now = moment();
            state.toasts = state.toasts.filter((toast) => {
                const expirationTime = moment(toast.date).add(toast.duration, 'milliseconds');
                return toast.id !== payload && now.isBefore(expirationTime);
            });
        },
    },
});

export const { addToast, deleteToast, clearToasts } = toastSlice.actions;

export default toastSlice.reducer;

export const toastSelector = (state: { toastStore: ToastState }) => state.toastStore;
