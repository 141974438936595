import { ContextType } from '@contexts/toast-context';
import Type from '@ctypes/toast';
import { addToast, clearToasts, deleteToast } from '@redux/reducers/toast-reducer';
import { AppDispatch } from '@redux/store';

export let Toast: ContextType;

export default function (dispatch: AppDispatch) {
    const add = (type: Type, message: string, duration?: number) => {
        const id = Math.floor(Math.random() * 10000000);
        const date = new Date();
        const _duration = duration == undefined ? 400 : duration;
        dispatch(addToast({ type, message, id, date: date.toISOString(), duration: _duration }));
        return id;
    };
    // Remove existing toast by his id
    const remove = (id: number) => {
        dispatch(deleteToast(id));
    };

    const clear = () => {
        dispatch(clearToasts());
    };

    Toast = {
        // Publish successfull message
        success: (message: string, duration?: number) => {
            return add('success', message, duration);
        },
        // Publish warning message
        warning: (message: string, duration?: number) => {
            return add('warning', message, duration);
        },
        // Publish information message
        info: (message: string, duration?: number) => {
            return add('info', message, duration);
        },
        // Publish message when errors
        error: (message: string, duration?: number) => {
            return add('error', message, duration);
        },
        remove,
        clear,
    };
}
