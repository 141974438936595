import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import { Party } from 'interfaces/party.interface';
import tournamentService from '@services/tournament-service';
import { Tournament } from 'interfaces/tournament.interface';

export type TournamentType = {
    tournaments: Array<Tournament>;
    selectedWaitingGame?: Party;
    error: string | null;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY;
};

const initialState: TournamentType = {
    tournaments: [],
    error: null,
    status: EMPTY,
};

export const getTournaments: any = createAsyncThunk<Tournament[], number>(
    // The name of the action
    'tournaments/getTournaments',
    // The payload creator
    async (page: number, { rejectWithValue }) => {
        try {
            return await tournamentService.getTournaments(page);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const tournamentsSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'tournaments',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        setLoading: (state, action) => {
            state.status = action.payload;
        },
        setTournaments: (state, { payload }: PayloadAction<Tournament[]>) => {
            state.tournaments = [...payload];
            state.status = SUCCESSFUL;
        },
        refreshTournament: (state, { payload }: PayloadAction<Tournament>) => {
            state.tournaments = state.tournaments.map((t) => {
                if (t.id == payload.id) {
                    return payload;
                }
                return t;
            });
            state.status = SUCCESSFUL;
        },
        clearTournaments: (state) => {
            state.tournaments = [];
            state.status = LOADING;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: (builder) => {
        builder
            .addCase(getTournaments.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(getTournaments.fulfilled, (state, { payload }: PayloadAction<Tournament[]>) => {
                state.status = SUCCESSFUL;
                if (Array.isArray(payload)) {
                    state.tournaments = payload;
                }
            })
            .addCase(getTournaments.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.error.message;
            });
    },
});
export const { setLoading, setTournaments, clearTournaments, refreshTournament } = tournamentsSlice.actions;
export default tournamentsSlice.reducer;
