import { DEFAULT_ROUTE } from '@config/app';
import { __URL_DATA__ } from '@constants/storage';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export type ProtectedRouteProps = {
    isPublic?: boolean;
    isAuthorized?: boolean;
    isLogged?: boolean;
};

export function ProtectedRoute({ isPublic, isAuthorized, isLogged }: ProtectedRouteProps): React.ReactElement {
    // Check if the route is public and the user is already logged in
    let storedUrl = secureLocalStorage.getItem(__URL_DATA__);
    storedUrl = storedUrl ? JSON.parse(storedUrl as string) : null;

    if (!isPublic && isLogged) {
        secureLocalStorage.removeItem(__URL_DATA__);
    }

    if (isPublic && isLogged) {
        // If the user is logged in and the route is public, redirect to dashboard
        if (storedUrl) {
            return <Navigate to={storedUrl as string} />;
        } else {
            return <Navigate to={DEFAULT_ROUTE} />;
        }
    }

    // If the route is public or the user is authorized, render the outlet (the component)
    if (isPublic || isAuthorized) {
        return <Outlet />;
    }

    if (!isPublic && !isLogged) {
        const redirectPath = location.pathname + location.search;
        secureLocalStorage.setItem(__URL_DATA__, JSON.stringify(redirectPath));
    }

    // Otherwise, redirect the user to the sign-in page if they're not authorized
    return <Navigate to="/auth/signin" />;
}
