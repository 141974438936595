import tournamentService from '@services/tournament-service';
import {Tournament, TournamentRound} from 'interfaces/tournament.interface';
import {useState} from 'react';
import {useToast} from './use-toast';
import {useDispatch} from 'react-redux';
import {getTournaments} from '@redux/reducers/tournament-reducer';
import {useTranslation} from 'react-i18next';
import {MODAL_TYPES, useModal} from '@contexts/global-modal-context';
import moment from 'moment';

export const useTournament = () => {
    const [isJoinning, setIsJoinning] = useState<boolean>(false);
    const [isLeaving, setIsLeaving] = useState<boolean>(false);
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const toaster = useToast();
    const dispatch = useDispatch();
    const {t} = useTranslation(['competition']);
    const {showModal} = useModal();

    const joinTournament = (tournament: Tournament, callback?: (status: boolean) => void) => {
        setIsJoinning(true);
        tournamentService
            .joinTournament(tournament._id)
            .then((status: boolean) => {
                setIsJoinning(false);
                if (status) {
                    toaster?.success(t('join_success'));
                }
                callback?.(status);
                dispatch(getTournaments(1));
            })
            .catch((error) => {
                setIsJoinning(false);
                console.log(error);
            });
    };

    const leaveTournament = (tournament: Tournament, callback?: (status: boolean) => void) => {
        setIsLeaving(true);
        tournamentService
            .quitTournament(tournament._id)
            .then((status) => {
                if (status) {
                    toaster?.success(t('leave_success'));
                }
                callback?.(status);
                dispatch(getTournaments(1));
            })
            .finally(() => {
                setIsLeaving(false);
            });
    };

    const showDetails = (tournamentId: string, callback?: (tournament: Tournament) => void) => {
        setIsShowing(true);
        tournamentService
            .showTournament(tournamentId)
            .then((_tournament) => {
                callback?.(_tournament);
                if (_tournament?.automation) {
                    showModal(MODAL_TYPES.TOURNAMENT_DETAILS_MODAL, {tournament: _tournament});
                } else {
                    toaster?.success(t('invalid_tournament_details'));
                }
            })
            .finally(() => {
                setIsShowing(false);
            });
    };

    const findCurrentRound = (rounds: TournamentRound[]) => {
        const now = moment(); // Get the current date and time

        return rounds.find((round) => {
            const startAt = moment(round.start_at);
            const endAt = moment(round.end_at);

            return now.isBetween(startAt, endAt, 'seconds', '[]');
        });
    };

    const canLeaveTournament = (tournament: Tournament) => {
        return tournament.register_fees <= 0;
    }

    return {
        isJoinning,
        isLeaving,
        isShowing,
        joinTournament,
        leaveTournament,
        showDetails,
        findCurrentRound,
        canLeaveTournament,
    };
};
