import { useSocket } from '@hooks/use-socket';
import React from 'react';
import Event from '@constants/events';
import { e } from '@utils/encryption-wrapper';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { useDispatch } from 'react-redux';
import { playCard } from '@redux/reducers/game-reducer';
import Avatars from '@constants/avatars';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks/use-auth';
import { Alert } from '@utils/alert';
import CardUtil from '@utils/card';
import Cards, { CardIndex } from '@constants/cards';
import Icon from '@components/ui/icon';
import './game-footer.css';
import GameActions from '../game-actions';

import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
type GameFooterProps = {
    currentPlayerIndex: number;
}

function GameFooter(prop: GameFooterProps): React.ReactElement {
    const {currentPlayerIndex} = prop;
    const { currentPlayer, game } = useSelector((state: RootState) => state.gameStore);
    const { isReferee, user } = useAuth();
    const { socket } = useSocket();
    const dispatch = useDispatch();
    const { t } = useTranslation(['alert']);
    const { showModal } = useModal();

    const _getPlayerIndex = (): number => {
        if (!(user && isReferee)) {
            return game?.players.findIndex((player) => player.id === user?.id) || 0;
        }
        return 0;
    };

    const _onCardClick = (cardId: CardIndex) => {
        if (!game) return;

        if (game?.isFinish) {
            Alert.alert(t('warning_avert_label'), t('ended_part'));
            return;
        }

        const index_player = _getPlayerIndex();
        const player = game?.players[index_player];

        if (!player) {
            return;
        }

        if (player.resigned) {
            Alert.alert(t('warning_avert_label'), t('no_longer_in_part'));
        } else if (game?.player_token !== index_player) {
            socket?.emit(Event.SEND_NEXT_CARD, e(game?.part.id), e(cardId));
            // Alert.alert('Information', "Ce n'est pas votre tour !")
        } else {
            const index_controller = game.control_token;
            const controlPlayer = game.players[index_controller];
            const playCards = controlPlayer.playCards;

            if (playCards.length > 0 && index_player !== index_controller) {
                const controlerLastCard = controlPlayer.playCards[playCards.length - 1];
                const HasACardToPlay = player.initialCards.some(
                    (card) => player.playCards.indexOf(card) < 0 && CardUtil.sameColor(card, controlerLastCard),
                );

                if (HasACardToPlay && !CardUtil.sameColor(cardId, controlerLastCard)) {
                    Alert.alert(t('warning_avert_label'), t('cannot_play_card'));
                    return;
                }
            }

            socket?.emit(Event.SEND_CARD, e(game.part.id), e(cardId));

            dispatch(playCard({ card: cardId, player: currentPlayer }));
        }
    };

    const _onStoppedBullets = () => {
        if (currentPlayer && currentPlayer.playCards.length > 0) {
            Alert.alert(t('warning_label'), t('cannot_win_stopped_balls'));
            return;
        }

        Alert.alert(
            t('warning_label'),
            t('stopped_balls_win_question', { win_options: t('stopped_balls_list', { ns: 'game' }) }),
            [
                {
                    text: t('no', { ns: 'global' }),
                    onPress: () => null,
                },
                {
                    text: t('yes', { ns: 'global' }),
                    onPress: () => socket?.emit(Event.SPECIAL_WIN, e(game?.part.id || '')),
                },
            ],
            { cancelable: true },
        );
    };

    const toggleSubmenu = () => {
        showModal(MODAL_TYPES.GAME_ACTIONS_MODAL, {});
    };

    return (
        <>
            <div className="player-side">
                <div className="cards web-hide">
                    {currentPlayer?.initialCards
                        .filter((c) => !currentPlayer.playCards.includes(c))
                        .map((card, index) => (
                            <div
                                className={`card ${currentPlayer.nextCard == card ? 'next-card' : ''}`}
                                key={index}
                                onClick={() => _onCardClick(card)}
                            >
                                <div className="icon icon-card" data-card-number="3" data-card-color="back-heart">
                                    <img src={Cards[card]} alt="Card 11" />
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="player-side">
                <div className="user-datas web-hide width-auto">
                    {!isReferee && game?.part.special_mode && (
                        <button type="button" onClick={_onStoppedBullets} className="small success">
                            <span className="icon icon12">
                                <Icon name={'settings'} className="special-mode-icon"></Icon>
                            </span>
                        </button>
                    )}
                </div>
                <div className="user-avatar current-player web-hide">
                    <img src={Avatars[22]} alt="" />
                    <span className={`player-pseudo ${game?.beginner_token == currentPlayerIndex ? 'beginner' : ''}`}>@{currentPlayer?.pseudo}</span>
                </div>
                <div className="player-block mobile-hide">
                    <div className="active-player">
                        <div className="user-datas">
                            {!isReferee && game?.part.special_mode && (
                                <button type="button" onClick={_onStoppedBullets} className="small success">
                                    {t('stopped_balls', { ns: 'game' })}
                                    <span className="icon icon12">
                                        <Icon name={'settings'} className="special-mode-icon"></Icon>
                                    </span>
                                </button>
                            )}
                        </div>
                        <div className="user-avatar current-player">
                            <img src={Avatars[22]} alt="" />
                            <span className={`player-pseudo ${game?.beginner_token == currentPlayerIndex ? 'beginner' : ''}`}>@{currentPlayer?.pseudo}</span>
                        </div>
                    </div>
                    <div className="cards mobile-hide">
                        {currentPlayer?.initialCards
                            .filter((c) => !currentPlayer.playCards.includes(c))
                            .map((card, index) => (
                                <div
                                    className={`card ${currentPlayer.nextCard == card ? 'next-card' : ''}`}
                                    key={index}
                                    onClick={() => _onCardClick(card)}
                                >
                                    <div className="icon icon-card" data-card-number="3" data-card-color="back-heart">
                                        <img src={Cards[card]} alt="Card 11" />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="player-actions web-hide">
                    <button type="button" onClick={toggleSubmenu} className="small success">
                        <span className="icon icon12">☰</span>
                    </button>
                </div>
                <div className="player-actions mobile-hide">
                    <GameActions />
                </div>
            </div>
        </>
    );
}

export default GameFooter;
