import React, { useEffect } from 'react';
import './toast.css';
import Type from '@ctypes/toast';

import { SuccessIcon, FailureIcon, WarningIcon, CloseIcon } from '@components/ui/icons';
import moment from 'moment';

interface Props {
    type: Type;
    message: string;
    onClose(id: number): void;
    id: number;
    duration: number;
    date: Date;
}

const Toast: React.FunctionComponent<Props> = ({ message, type, id, date, onClose, duration }: Props) => {
    // rest of the code

    const iconMap: any = {
        success: <SuccessIcon />,
        error: <FailureIcon />,
        warning: <WarningIcon />,
        info: <WarningIcon />,
    };

    const toastIcon: string = iconMap[type] || null;

    const handleDismiss = () => {
        onClose(id);
    };

    useEffect(() => {
        const elapsedTime = moment().diff(moment(date), 'milliseconds');
        const remainingTime = Math.max(duration - elapsedTime, 0);
        let timerID: any;
        if (remainingTime > 0) {
            timerID = setTimeout(() => {
                handleDismiss();
            }, remainingTime);
        } else {
            handleDismiss();
        }
        return () => {
            if (timerID) {
                clearTimeout(timerID);
            }
        };
    }, [duration, date, handleDismiss]);

    return (
        <div className={`toast toast--${type}`} role="alert">
            <div className="toast-message">
                {toastIcon && <div className="icon icon--lg icon--thumb">{toastIcon}</div>}
                <p>{message}</p>
            </div>
            <button className="toast-close-btn" onClick={() => onClose(id)}>
                <span className="icon icon--thumb">
                    <CloseIcon />
                </span>
            </button>
        </div>
    );
};

export default Toast;
