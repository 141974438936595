import { JSONValue } from '@ctypes/json-value';
import secureLocalStorage from 'react-secure-storage';

export default {
    get(key: string, defaultValue: any = null) {
        return new Promise((resolve, reject) => {
            try {
                const data = secureLocalStorage.getItem(key);
                if (data === undefined || data === null) {
                    resolve(defaultValue);
                } else {
                    resolve(data);
                }
            } catch (error) {
                reject(error);
            }
        });
    },
    getMultiple(keys: string[], defaultValues = []) {
        return new Promise((resolve, reject) => {
            try {
                const data: JSONValue = {};
                keys.forEach((key: string, i: number) => {
                    const value = secureLocalStorage.getItem(key);
                    data[key] = value || defaultValues[i];
                });
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    },
    set(key: string, value: JSONValue) {
        return secureLocalStorage.setItem(key, value);
    },
    setMultiple(data: JSONValue[]) {
        Object.keys(data).forEach((key: any) => {
            secureLocalStorage.setItem(key, data[key]);
        });
    },
    remove(key: string) {
        return secureLocalStorage.removeItem(key);
    },
    clear() {
        return secureLocalStorage.clear();
    },
};
