import React, { HTMLAttributes } from 'react';
import './spinner.css';

const animations = [
    { r: 0.0 },
    {
        name: 'spinners-round-filled-inner',
        r: 12.66,
    },
    {
        name: 'spinners-round-filled-center',
        r: 20.32,
    },
    {
        name: 'spinners-round-filled-outer',
        r: 27.5,
    },
];

interface Props {
    speed?: number;
    still?: boolean;
    thickness?: number;
    size?: number;
    style?: React.CSSProperties;
    color?: string;
}

type SpinnerProps = HTMLAttributes<SVGElement> & Props;

const Spinner = ({
    speed = 100,
    still = false,
    thickness = 100,
    color = '#38ad48',
    size = 10,
    ...svgProps
}: SpinnerProps): React.ReactElement => (
    <svg fill="#FFF" {...svgProps} viewBox="0 0 66 66">
        {animations.map((animation) => (
            <circle
                key={animation.name || 'still'}
                cx="33"
                cy="33"
                fill={color} // Use color prop
                r={animation.r * (animation.name ? thickness / 100 : 1)}
                style={{
                    opacity: animation.name ? 0.25 : 1,
                    transformOrigin: 'center',
                    animation:
                        !animation.name || still ? 'none' : `${animation.name} ${140 / speed}s ease-in-out infinite`,
                }}
            />
        ))}
    </svg>
);

export default Spinner;
