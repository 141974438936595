import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { LOADING } from '@models/status';
import Spinner from '@components/ui/spinner';
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';
import TournamentWidget from '@components/widgets/tournament';
import { getTournaments } from '@redux/reducers/tournament-reducer';
import { useLocation } from 'react-router-dom';
import { useTournament } from '@hooks/use-tournament';

function TournamentList(): React.ReactElement {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation(['competition']);
    const { status, tournaments } = useSelector((state: RootState) => state.tournamentStore);
    const { showDetails } = useTournament();

    const fetchTournaments = () => {
        dispatch(getTournaments(1));
    };

    useEffect(() => {
        dispatch(getTournaments(1));
    }, []);

    const onRefresh = () => {
        fetchTournaments();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tourParam = searchParams.get('tour');

        if (tourParam) {
            setTimeout(() => {
                showDetails(tourParam);
            }, 100);
        }
    }, [location]);

    return (
        <div className="list-page-content w-100">
            <div className="page-intro w-100">
                <div>
                    <h2 className="page-title">{t('title')}</h2>
                    <p className="w-75">{t('sub_title')}</p>
                </div>
            </div>
            {status != LOADING && tournaments.length > 0 && (
                <div className="page-section">
                    <div className="widget-group backdropped">
                        <div className="group-header">
                            <div className="group-sub-title d-flex justify-content-between align-items-center">
                                <h5 className="sub-title-description">{t('suggest')}</h5>
                                <button
                                    className="w-auto success text-center d-flex justify-content-center "
                                    onClick={onRefresh}
                                >
                                    <span className="icon icon12">
                                        <RefreshIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="group-container">
                            <div className="group-content">
                                <div className="list-flex">
                                    {tournaments.map((tournament, index) => (
                                        <TournamentWidget
                                            tournament={tournament}
                                            key={index}
                                            isPendingPart={true}
                                        ></TournamentWidget>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(status == LOADING || !tournaments.length) && (
                <div className="page-section mobile-padding-security mt-3">
                    <div className="d-flex align-items-center widget info-card large opaced create-game-card  text-center">
                        <p className="text-center">{t('no_tournament')}</p>
                        <span className="flex-none" style={{ width: 100 }}>
                            <Spinner color="#FFF" />
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TournamentList;
