import React, { FormEvent, useState } from 'react';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import Modal from '@components/ui/modal';
import Withdrawal from '@models/withdrawal';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import TransactionService from '@services/transaction-service';
import { Alert } from '@utils/alert';
import regex from '@constants/regex';
import { useWithdrawal } from '@hooks/use-withdrawal';

function WithdrawalModal(): React.ReactElement {
    const { t } = useTranslation(['withdrawal']);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { hideModal } = useModal();
    const [password, setPassword] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);
    const [errors, setErrors] = useState<{ password?: string; amount?: string }>({});
    const { setFeedbackReceived } = useWithdrawal();
    // Get the posts from the store

    const onWithdrawal = () => {
        setFeedbackReceived(false);
        setIsLoading(true);
        const data: Withdrawal = { password, amount };
        TransactionService.withdrawal(data)
            .then((response: any) => {
                Alert.success(response.title, response.message, null, { cancelable: false });
                setIsLoading(false);
                hideModal();
            })
            .then(() => {
                setIsLoading(false);
            });
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const withdrawalHandler = (event: FormEvent) => {
        event.preventDefault();
        if (isValidData()) {
            onWithdrawal();
        }
    };

    const isValidData = () => {
        const errors: { password?: string; amount?: string } = {};

        if (!regex.isValidWithdrawalAmount(amount.toString())) {
            errors.amount = t('incorrect_disburse_amount', { ns: 'alert' });
        }
        if (password.trim() === '') {
            errors.password = t('password_required', { ns: 'alert' });
        }
        setErrors(errors);
        return !Object.values(errors).some((value) => value != undefined);
    };

    return (
        <Modal show={true} id="party-modal" hideOnAreaClick={false} title={t('Décaisser mon argent')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <form onSubmit={withdrawalHandler}>
                        <BInputField
                            type="number"
                            label={t('disburse_amount', { ns: 'forms' })}
                            id="withdrawal-amount"
                            value={amount}
                            name="amount"
                            onInput={setAmount}
                            placeholder="Ex : 100"
                            error={errors.amount}
                        ></BInputField>
                        <BInputField
                            type="password"
                            label={t('password_placeholder', { ns: 'forms' })}
                            id="password"
                            name="password"
                            value={password}
                            onInput={setPassword}
                            error={errors.password}
                            placeholder={t('password_placeholder', { ns: 'forms' })}
                        ></BInputField>
                        <Button
                            label={t('validate', { ns: 'validate' })}
                            icon="security"
                            disabled={isLoading}
                            loading={isLoading}
                            className="mt-3"
                        ></Button>
                    </form>
                </div>
            </div>
        </Modal>
    );
}

export default WithdrawalModal;
