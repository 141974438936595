import React, {useEffect, useState} from 'react';
import Modal from '@components/ui/modal';
import './tournament-details.css';
import {useTranslation} from 'react-i18next';
import {Tournament} from 'interfaces/tournament.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import {formatAmount} from '@utils/helpers';
import {useAuth} from '@hooks/use-auth';
import Cookies from 'js-cookie';
import moment from 'moment';
import {useTournament} from '@hooks/use-tournament';
import Spinner from '@components/ui/spinner';
import tournamentService from '@services/tournament-service';
import TournamentRoundList from '@features/tournament-details/tournament-round-list/tournament-round-list';
import {MODAL_TYPES, useModal} from '@contexts/global-modal-context';
import TournamentClassification from '@features/tournament-details/tournament-classification';
import TournamentSideTabInfos from '@features/tournament-details/tournament-side';
import {capitalize} from 'lodash';

type TournamentDetailsProps = {
    tournament: Tournament;
};

enum TAB {
    TOUR,
    PARTY,
    CLASSIFICATION,
}

enum SIDE_TAB {
    DETAILS,
    REGISTERED_USERS,
}

function TournamentDetailsModal(props: TournamentDetailsProps): React.ReactElement<TournamentDetailsProps> {
    const {t} = useTranslation(['competition']);
    const {user} = useAuth();
    const [tournament, setTournament] = useState<Tournament>(props.tournament);
    const [subscrited, setSubscribed] = useState<boolean>(false);
    const [isLeaving, setIsLeaving] = useState<boolean>(false);
    const {isJoinning, joinTournament, canLeaveTournament} = useTournament();
    const [tab, setTab] = useState<TAB>(TAB.TOUR);
    const [sideTab, setSideTab] = useState<SIDE_TAB>(SIDE_TAB.DETAILS);
    const {hideModal, showModal} = useModal();
    const locale = Cookies.get('i18next') || 'fr';
    const [showDetails, setShowDetails] = useState<boolean>(false);

    moment.locale(locale);

    useEffect(() => {
        const _subscribed = tournament.users.find((u) => u.user_id == user?.id) != null;
        setSubscribed(_subscribed);
    }, [tournament]);

    const onJoinTournament = () => {
        joinTournament(tournament, onRefresh);
    };

    const onRefresh = (status: boolean) => {
        if (status) {
            tournamentService.showTournament(tournament._id).then((_tournament) => {
                setTournament(_tournament);
            });
        }
    };

    const onQuit = () => {
        if (canLeaveTournament(tournament)) {
            setIsLeaving(true);
            tournamentService
                .quitTournament(tournament._id)
                .then((status) => {
                    onRefresh(status);
                })
                .finally(() => {
                    setIsLeaving(false);
                });
        }
    };

    const onGoBack = () => {
        switch (sideTab) {
            case SIDE_TAB.DETAILS:
                hideModal();
                break;
            case SIDE_TAB.REGISTERED_USERS:
                setSideTab(SIDE_TAB.DETAILS);
                break;
        }
    };

    const handleShare = () => {
        const title = `${tournament.name} (Du ${moment(tournament.automation.rounds[0].start_at).format('D MMM YYYY [at] HH:mm [GMT]Z')} au ${moment(tournament.end_at).format('D MMM YYYY [at] HH:mm [GMT]Z')}), La mise est de ${tournament.register_fees} FCFA, le gagnant raffle le jackpot de ${tournament.cash_prize} FCFA`;
        const url = window.location + '?tour=' + tournament._id;
        showModal(MODAL_TYPES.SHARE_MODAL, {imageUrl: tournament.picture, title, url});
    };

    useEffect(() => {
        if (isJoinning && tournament.users.find((u) => u.user_id == user?.id)) {
        }
    }, [isJoinning, tournament]);

    if (!tournament.automation) {
        return <></>;
    }

    return (
        <Modal className="tour tour-flow-1" hideHeader={true} show={true} id="party-modal" title={t('title')}>
            <div className="section full-page">
                <div className="container grid-full tour-screen">
                    <div className="tour-form-side mobile-hide .mob-span4 opaced">
                        <TournamentSideTabInfos
                            tournament={tournament}
                            subscrited={subscrited}
                            isJoinning={isJoinning}
                            isLeaving={isLeaving}
                            onGoBack={onGoBack}
                            onJoinTournament={onJoinTournament}
                            onQuitTournament={onQuit}
                        />
                    </div>
                    <div className="tour-plan-side">
                        <div className="modal-return opaced web-hide">
                            <span className="icon icon12" onClick={() => hideModal()}>
                                <svg
                                    width="10"
                                    height="18"
                                    viewBox="0 0 10 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 1L1 9L9 17"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>
                        <div className="plan-header">
                            <div className="header-sub-body">
                                <div className="event-card-globals">
                                    <div className="even-fund">
                                        <span className="icon">
                                            <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 13 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.06755 6.40638C6.96882 6.40638 6.87212 6.37251 6.79432 6.30894C6.69397 6.22694 6.63574 6.10419 6.63574 5.9746V1.45717C6.63574 1.21871 6.82906 1.02539 7.06752 1.02539L11.2979 1.02539C11.5248 1.02539 11.7346 1.12832 11.8735 1.30783C12.0124 1.48731 12.0594 1.71626 12.0025 1.93596L11.5036 3.86067C11.2388 4.8823 10.4388 5.67804 9.41576 5.93737C9.40908 5.93906 9.40239 5.94058 9.39565 5.94195L7.15354 6.39773C7.12503 6.40354 7.0962 6.40638 7.06755 6.40638V6.40638ZM7.49935 1.88895V5.4462L9.21307 5.09783C9.92594 4.91349 10.4828 4.35724 10.6677 3.64398L11.1226 1.88898L7.49935 1.88898V1.88895Z"
                                                    fill="#FFC250"
                                                ></path>
                                                <path
                                                    d="M4.95862 6.40638C4.92996 6.40638 4.90118 6.40354 4.8726 6.39773L2.63049 5.94195C2.62377 5.94058 2.61708 5.93903 2.61043 5.93737C1.5874 5.67802 0.787375 4.8823 0.522579 3.86065L0.0236652 1.93596C-0.0332965 1.71628 0.0136822 1.48733 0.152598 1.30785C0.291491 1.12834 0.501321 1.02539 0.728276 1.02539L4.95862 1.02539C5.19708 1.02539 5.3904 1.21871 5.3904 1.45717V5.9746C5.3904 6.10419 5.33219 6.22694 5.23182 6.30894C5.15407 6.37251 5.05734 6.40638 4.95862 6.40638V6.40638ZM2.81312 5.09783L4.52686 5.4462V1.88895L0.9036 1.88895L1.35852 3.64396C1.54338 4.35724 2.10024 4.91347 2.81312 5.09783Z"
                                                    fill="#FFC250"
                                                ></path>
                                                <path
                                                    d="M7.49114 9.70062C6.37051 9.33071 5.58945 8.17851 5.54801 6.8527C5.59318 6.6432 5.61681 6.42658 5.61681 6.20569V4.74023H5.30186V6.20569C5.30186 7.57206 4.39785 8.77361 3.08496 9.15221V10.0401H7.76315V9.79042L7.49114 9.70062Z"
                                                    fill="#FFD064"
                                                ></path>
                                                <path
                                                    d="M8.6687 9.07378C7.51692 8.74164 6.72382 7.68754 6.72382 6.4888V4.74023H5.3022V6.20569C5.3022 6.4266 5.27857 6.6432 5.2334 6.8527C5.27483 8.17851 6.0559 9.33071 7.17653 9.70062L7.44856 9.79042V10.0401H8.94073V9.15221L8.6687 9.07378Z"
                                                    fill="#FFC250"
                                                ></path>
                                                <path
                                                    d="M8.09314 9.14258H2.57519C2.36 9.14258 2.18555 9.31701 2.18555 9.53222V11.4886H8.48276V9.53222C8.48279 9.31701 8.30833 9.14258 8.09314 9.14258Z"
                                                    fill="#FFE07D"
                                                ></path>
                                                <path
                                                    d="M9.44961 9.14258H7.89062C8.10581 9.14258 8.28027 9.31703 8.28027 9.53222V11.4886H9.83925V9.53222C9.83923 9.31703 9.6648 9.14258 9.44961 9.14258Z"
                                                    fill="#FFD064"
                                                ></path>
                                                <path
                                                    d="M7.97314 2.71583V0.529297L2.77148 0.529297V2.64017C2.77148 4.37192 3.70899 5.96798 5.22154 6.81124C5.33406 6.87399 5.45219 6.92237 5.57332 6.95646C7.05888 6.06449 7.97314 4.45632 7.97314 2.71583Z"
                                                    fill="#FFE07D"
                                                ></path>
                                                <path
                                                    d="M7.69383 0.529297V2.64017C7.69383 4.37192 6.75632 5.96798 5.24374 6.81124C5.23996 6.81333 5.2362 6.81542 5.23242 6.81749C5.72295 7.0859 6.31733 7.08381 6.80626 6.81124C8.31881 5.96795 9.25634 4.37192 9.25634 2.64017V0.529297L7.69383 0.529297Z"
                                                    fill="#FFD064"
                                                ></path>
                                                <path
                                                    d="M9.26125 11.8117V11.5678C9.26125 11.3292 9.06796 11.1359 8.82945 11.1357H1.83634C1.59773 11.1357 1.4043 11.3292 1.4043 11.5678C1.4043 11.8064 1.59773 11.9998 1.83634 11.9998H9.05901L9.26125 11.8117Z"
                                                    fill="#FFD064"
                                                ></path>
                                                <path
                                                    d="M10.1902 11.1357H8.62695C8.86556 11.1357 9.05899 11.3292 9.05899 11.5678V11.9998H10.1902C10.4288 11.9998 10.6223 11.8064 10.6223 11.5678C10.6223 11.3292 10.4288 11.1357 10.1902 11.1357Z"
                                                    fill="#FFC250"
                                                ></path>
                                                <path
                                                    d="M8.82572 0.513243V0.168677L8.49088 0L2.48685 0C2.20341 0 1.97363 0.229773 1.97363 0.513219C1.97363 0.796665 2.20341 1.02644 2.48685 1.02644L8.3125 1.02644C8.59592 1.02649 8.82572 0.796689 8.82572 0.513243Z"
                                                    fill="#FFD064"
                                                ></path>
                                                <path
                                                    d="M9.54003 0L8.49076 0V0.513219C8.49076 0.796665 8.26096 1.02644 7.97754 1.02644H9.54005C9.8235 1.02644 10.0533 0.796665 10.0533 0.513219C10.0533 0.229773 9.82347 0 9.54003 0V0Z"
                                                    fill="#FFC250"
                                                ></path>
                                                <path
                                                    d="M8.28233 10.3667H3.74635C3.64481 10.3667 3.5625 10.2844 3.5625 10.1829C3.5625 10.0813 3.64481 9.99902 3.74635 9.99902H8.28233C8.38388 9.99902 8.46619 10.0813 8.46619 10.1829C8.46619 10.2844 8.38388 10.3667 8.28233 10.3667Z"
                                                    fill="#F4FBFF"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="fund-amount">
                                            {formatAmount(tournament.cash_prize || 0)}
                                            <span className="currency">FCFA</span>
                                        </span>
                                    </div>
                                    <div className="tournament-date">
                                        <div className="plan-header-infos">
                                            <div className="tour-date opaced">
                                                <span className="tour-day">
                                                    {moment(tournament.automation.rounds[0].start_at).format('DD')}
                                                </span>
                                                <span className="tour-month">
                                                    <b>
                                                        {capitalize(
                                                            moment(tournament.automation.rounds[0].start_at).format(
                                                                'MMMM',
                                                            ),
                                                        )}
                                                    </b>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="event-time-discount">
                                            Debute{' '}
                                            {moment(tournament.automation.rounds[0].start_at).format(
                                                'ddd DD MMM YYYY HH:mm:ss [GMT]Z',
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tour-game-id">
                            <div className="web-hide show-details">
                                <button className="opaced" type="button" onClick={() => setShowDetails(!showDetails)}>
                                    {!showDetails && (
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 -4.5 20 20"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g
                                                    id="Dribbble-Light-Preview"
                                                    transform="translate(-220.000000, -6684.000000)"
                                                    fill="#FFF"
                                                >
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <path
                                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                                            id="arrow_down-[#338]"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    )}
                                    {showDetails && (
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 -4.5 20 20"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g
                                                    id="Dribbble-Light-Preview"
                                                    transform="translate(-260.000000, -6684.000000)"
                                                    fill="#FFF"
                                                >
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <path
                                                            d="M223.707692,6534.63378 L223.707692,6534.63378 C224.097436,6534.22888 224.097436,6533.57338 223.707692,6533.16951 L215.444127,6524.60657 C214.66364,6523.79781 213.397472,6523.79781 212.616986,6524.60657 L204.29246,6533.23165 C203.906714,6533.6324 203.901717,6534.27962 204.282467,6534.68555 C204.671211,6535.10081 205.31179,6535.10495 205.70653,6534.69695 L213.323521,6526.80297 C213.714264,6526.39807 214.346848,6526.39807 214.737591,6526.80297 L222.294621,6534.63378 C222.684365,6535.03868 223.317949,6535.03868 223.707692,6534.63378"
                                                            id="arrow_up-[#337]"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    )}
                                </button>
                            </div>
                            <div className="description">
                                <h4 className="tour-name">{t('tournament_name')}</h4>
                                <span className="play-type">{tournament.name}</span>
                            </div>
                            <button className="default">
                                <span className="mobile-hide">Activer un rappel</span>
                                <span className="icon icon16">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10 6.33333C10 5.78105 9.55229 5.33333 9 5.33333C8.44772 5.33333 8 5.78105 8 6.33333H10ZM9 9.88889H8C8 10.1541 8.10536 10.4085 8.29289 10.596L9 9.88889ZM10.0707 12.3738C10.4612 12.7643 11.0944 12.7643 11.4849 12.3738C11.8754 11.9832 11.8754 11.3501 11.4849 10.9596L10.0707 12.3738ZM15.0404 0.292893C14.6499 -0.0976311 14.0168 -0.0976311 13.6262 0.292893C13.2357 0.683417 13.2357 1.31658 13.6262 1.70711L15.0404 0.292893ZM16.2929 4.37377C16.6834 4.7643 17.3166 4.7643 17.7071 4.37377C18.0976 3.98325 18.0976 3.35008 17.7071 2.95956L16.2929 4.37377ZM0.292893 2.95956C-0.0976311 3.35008 -0.0976311 3.98325 0.292893 4.37377C0.683418 4.7643 1.31658 4.7643 1.70711 4.37377L0.292893 2.95956ZM4.37377 1.70711C4.7643 1.31658 4.7643 0.683417 4.37377 0.292893C3.98325 -0.0976311 3.35008 -0.0976311 2.95956 0.292893L4.37377 1.70711ZM8 6.33333V9.88889H10V6.33333H8ZM8.29289 10.596L10.0707 12.3738L11.4849 10.9596L9.70711 9.18178L8.29289 10.596ZM15.1111 9.88889C15.1111 13.264 12.3751 16 9 16V18C13.4796 18 17.1111 14.3685 17.1111 9.88889H15.1111ZM9 16C5.62493 16 2.88889 13.264 2.88889 9.88889H0.888889C0.888889 14.3685 4.52036 18 9 18V16ZM2.88889 9.88889C2.88889 6.51382 5.62493 3.77778 9 3.77778V1.77778C4.52036 1.77778 0.888889 5.40925 0.888889 9.88889H2.88889ZM9 3.77778C12.3751 3.77778 15.1111 6.51382 15.1111 9.88889H17.1111C17.1111 5.40925 13.4796 1.77778 9 1.77778V3.77778ZM13.6262 1.70711L16.2929 4.37377L17.7071 2.95956L15.0404 0.292893L13.6262 1.70711ZM1.70711 4.37377L4.37377 1.70711L2.95956 0.292893L0.292893 2.95956L1.70711 4.37377Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                            </button>
                        </div>

                        {showDetails && (
                            <div className="tour-form-side-2 web-hide .mob-span4 opaced">
                                <TournamentSideTabInfos
                                    tournament={tournament}
                                    subscrited={subscrited}
                                    isJoinning={isJoinning}
                                    isLeaving={isLeaving}
                                    onGoBack={onGoBack}
                                    onJoinTournament={onJoinTournament}
                                    onQuitTournament={onQuit}
                                />
                            </div>
                        )}

                        <div className="tour-share opaced">
                            <div className="user">
                                <img src={defaultAvatar} alt=""/>
                                <div className="user-name">
                                    {user?.pseudo}{' '}
                                    <span>{subscrited ? 'Vous êtes inscrit' : "Vous n'êtes pas inscrit"}</span>
                                </div>
                            </div>
                            <div className="share-actions">
                                {!subscrited && (
                                    <button className="opaced success" type="button" onClick={onJoinTournament}>
                                        {t('register', {ns: 'actions'})}
                                        {isJoinning && (
                                            <span className="flex-none" style={{width: 20}}>
                                                <Spinner color="#FFF"/>
                                            </span>
                                        )}
                                        {!isJoinning && (
                                            <span className="icon icon12">
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <rect id="view-box" width="24" height="24" fill="none"/>
                                                    <path
                                                        id="Shape"
                                                        d="M5.75,17.5a.75.75,0,0,1,0-1.5h8.8A1.363,1.363,0,0,0,16,14.75v-12A1.363,1.363,0,0,0,14.55,1.5H5.75a.75.75,0,0,1,0-1.5h8.8A2.853,2.853,0,0,1,17.5,2.75v12A2.853,2.853,0,0,1,14.55,17.5ZM7.22,13.28a.75.75,0,0,1,0-1.061L9.939,9.5H.75A.75.75,0,0,1,.75,8H9.94L7.22,5.28A.75.75,0,0,1,8.28,4.22l4,4,.013.013.005.006.007.008.007.008,0,.005.008.009,0,0,.008.01,0,0,.008.011,0,0,.008.011,0,0,.008.011,0,0,.007.011,0,.005.006.01,0,.007,0,.008,0,.009,0,.006.006.011,0,0,.008.015h0a.751.751,0,0,1-.157.878L8.28,13.28a.75.75,0,0,1-1.06,0Z"
                                                        transform="translate(3.25 3.25)"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>
                                        )}
                                    </button>
                                )}
                                {subscrited && (
                                    <button className="btn danger" type="button"
                                            disabled={!canLeaveTournament(tournament)} onClick={onQuit}>
                                        {t('quit', {ns: 'actions'})}

                                        {isLeaving && (
                                            <span className="flex-none" style={{width: 20}}>
                                                <Spinner color="#FFF"/>
                                            </span>
                                        )}
                                        {!isLeaving && (
                                            <span className="icon icon12">
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M8.25 5.25L9 4.5H18L18.75 5.25V18.75L18 19.5H9L8.25 18.75V16.5H9.75V18H17.25V6H9.75V7.5H8.25V5.25Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.06068 12.7499L14.25 12.7499L14.25 11.2499L7.06068 11.2499L8.78035 9.53027L7.71969 8.46961L4.18936 11.9999L7.71969 15.5303L8.78035 14.4696L7.06068 12.7499Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>
                                        )}
                                    </button>
                                )}
                                <button className="opaced mobile-hide">
                                    <span className="mobile-hide">Peut-être</span>
                                    <span className="icon icon12">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.15"
                                                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M12 15.4355C12 14.8833 11.5523 14.4355 11 14.4355C10.4477 14.4355 10 14.8833 10 15.4355H12ZM10 15.4444C10 15.9967 10.4477 16.4444 11 16.4444C11.5523 16.4444 12 15.9967 12 15.4444H10ZM12 6.55556C12 6.00327 11.5523 5.55556 11 5.55556C10.4477 5.55556 10 6.00327 10 6.55556H12ZM10 12.7778C10 13.3301 10.4477 13.7778 11 13.7778C11.5523 13.7778 12 13.3301 12 12.7778H10ZM10 15.4355V15.4444H12V15.4355H10ZM10 6.55556V12.7778H12V6.55556H10ZM18 11C18 14.866 14.866 18 11 18V20C15.9706 20 20 15.9706 20 11H18ZM11 18C7.13401 18 4 14.866 4 11H2C2 15.9706 6.02944 20 11 20V18ZM4 11C4 7.13401 7.13401 4 11 4V2C6.02944 2 2 6.02944 2 11H4ZM11 4C14.866 4 18 7.13401 18 11H20C20 6.02944 15.9706 2 11 2V4Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                </button>
                                <button className="opaced mobile-hide">
                                    <span className="mobile-hide">Pas interessé</span>
                                    <span className="icon icon12">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.2627 6.15155C13.6532 5.76103 13.6532 5.12786 13.2627 4.73734C12.8721 4.34681 12.239 4.34681 11.8484 4.73734L13.2627 6.15155ZM4.73734 11.8484C4.34681 12.239 4.34681 12.8721 4.73734 13.2627C5.12786 13.6532 5.76103 13.6532 6.15155 13.2627L4.73734 11.8484ZM6.15156 4.73734C5.76103 4.34681 5.12787 4.34681 4.73735 4.73734C4.34682 5.12786 4.34682 5.76103 4.73734 6.15155L6.15156 4.73734ZM11.8484 13.2627C12.239 13.6532 12.8721 13.6532 13.2627 13.2627C13.6532 12.8721 13.6532 12.239 13.2627 11.8484L11.8484 13.2627ZM11.8484 4.73734L4.73734 11.8484L6.15155 13.2627L13.2627 6.15155L11.8484 4.73734ZM4.73734 6.15155L11.8484 13.2627L13.2627 11.8484L6.15156 4.73734L4.73734 6.15155ZM16 9C16 12.866 12.866 16 9 16V18C13.9706 18 18 13.9706 18 9H16ZM9 16C5.13401 16 2 12.866 2 9H0C0 13.9706 4.02944 18 9 18V16ZM2 9C2 5.13401 5.13401 2 9 2V0C4.02944 0 0 4.02944 0 9H2ZM9 2C12.866 2 16 5.13401 16 9H18C18 4.02944 13.9706 0 9 0V2Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                </button>
                                <button className="opaced" onClick={handleShare} type="button">
                                    <span className="mobile-hide">{t('share', {ns: 'actions'})}</span>
                                    <span className="icon icon12">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16 3.66667C16 4.58714 15.2538 5.33333 14.3333 5.33333V7.33333C16.3584 7.33333 18 5.69171 18 3.66667H16ZM14.3333 5.33333C13.4129 5.33333 12.6667 4.58714 12.6667 3.66667H10.6667C10.6667 5.69171 12.3083 7.33333 14.3333 7.33333V5.33333ZM12.6667 3.66667C12.6667 2.74619 13.4129 2 14.3333 2V0C12.3083 0 10.6667 1.64162 10.6667 3.66667H12.6667ZM14.3333 2C15.2538 2 16 2.74619 16 3.66667H18C18 1.64162 16.3584 0 14.3333 0V2ZM6.5 8.70137L12.3916 5.75557L11.4972 3.96671L5.60558 6.91252L6.5 8.70137ZM5.33333 9C5.33333 9.92048 4.58714 10.6667 3.66667 10.6667V12.6667C5.69171 12.6667 7.33333 11.025 7.33333 9H5.33333ZM3.66667 10.6667C2.74619 10.6667 2 9.92048 2 9H0C0 11.025 1.64162 12.6667 3.66667 12.6667V10.6667ZM2 9C2 8.07953 2.74619 7.33333 3.66667 7.33333V5.33333C1.64162 5.33333 0 6.97496 0 9H2ZM3.66667 7.33333C4.58714 7.33333 5.33333 8.07953 5.33333 9H7.33333C7.33333 6.97496 5.69171 5.33333 3.66667 5.33333V7.33333ZM12.3944 12.2458L6.50837 9.30281L5.61394 11.0917L11.5 14.0347L12.3944 12.2458ZM16 14.3333C16 15.2538 15.2538 16 14.3333 16V18C16.3584 18 18 16.3584 18 14.3333H16ZM14.3333 16C13.4129 16 12.6667 15.2538 12.6667 14.3333H10.6667C10.6667 16.3584 12.3083 18 14.3333 18V16ZM12.6667 14.3333C12.6667 13.4129 13.4129 12.6667 14.3333 12.6667V10.6667C12.3083 10.6667 10.6667 12.3083 10.6667 14.3333H12.6667ZM14.3333 12.6667C15.2538 12.6667 16 13.4129 16 14.3333H18C18 12.3083 16.3584 10.6667 14.3333 10.6667V12.6667Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="separator"></div>
                        <div className="tab tournament-tab">
                            <div className="tab-header">
                                <div
                                    className={`tab-thumb ${tab == TAB.TOUR ? 'active' : ''}`}
                                    onClick={() => setTab(TAB.TOUR)}
                                >
                                    {t('days')}
                                </div>
                                <div
                                    className={`tab-thumb ${tab == TAB.CLASSIFICATION ? 'active' : ''}`}
                                    onClick={() => setTab(TAB.CLASSIFICATION)}
                                >
                                    {t('tournament_standings')}
                                </div>
                            </div>
                            <div className="tab-content">
                                {tab == TAB.TOUR && <TournamentRoundList tournament={tournament}/>}
                                {tab == TAB.CLASSIFICATION && <TournamentClassification tournament={tournament}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default TournamentDetailsModal;
