import React, { useEffect, useState } from 'react';
import Avatars from '@constants/avatars';
import { GamePlayerInfo } from 'interfaces/game-player.interface';
import './game-board-player.css';
import Cards from '@constants/cards';
import hasControlImg from '@assets/images/icons/has-control.png';
import shouldPlayImg from '@assets/images/icons/should-play.png';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

type GameBoardPlayerProps = {
    token: number;
    player: GamePlayerInfo;
    totalPlayers: number;
    playerIndex: number;
};

type Position = 'top' | 'left' | 'right' | 'bottom';

function GameBoardPlayer(prop: GameBoardPlayerProps): React.ReactElement {
    const { token, player, playerIndex } = prop;
    const { game, user } = useSelector((state: RootState) => state.gameStore);
    const [hasControl, setHasControl] = useState<boolean>(false);
    const [shouldPlay, setShouldPlay] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>('bottom');

    useEffect(() => {
        setHasControl(isPlayerHasControl());
        setShouldPlay(isPlayerShouldPlay());
        setPosition(getPosition());
    }, [game, user, player]);

    const icon = {
        top: Avatars[1],
        left: Avatars[2],
        right: Avatars[3],
        bottom: Avatars[8],
    };

    const getPosition = (): Position => {
        switch (token) {
            case 0:
                return 'bottom';
            case 1:
                return 'right';
            case 2:
                return 'top';
            case 3:
                return 'left';
            default:
                return 'top';
        }
    };

    const isPlayerHasControl = (): boolean => {
        if (game) {
            return game.players[game.control_token].id === player.id;
        }
        return false;
    };

    const isPlayerShouldPlay = (): boolean => {
        if (game) {
            return game?.players[game.player_token].id === player.id;
        }
        return false;
    };

    return (
        <div className={`player player-${position}`}>
            {position != 'bottom' && (
                <div className="player-id">
                    <span className={`player-pseudo ${game?.beginner_token == playerIndex ? 'beginner' : ''}`} >@{player.pseudo}</span>
                </div>
            )}
            <div className="user-prizes">
                <div className="user-avatar">
                    {position != 'bottom' && (
                        <>
                            <img src={icon[position]} className={player.resigned ? 'resigned' : ''} alt="" />
                            <div className="player-card-count">
                                {player?.initialCards
                                    .filter((c) => !player.playCards.includes(c))
                                    .map((_, index) => (
                                        <div key={index} className={player.resigned ? 'card resigned' : 'card'}></div>
                                    ))}
                            </div>
                        </>
                    )}

                    <div className="player-hand">
                        <div className="d-flex gap-1">
                            <div className="player-tour">{hasControl && <img src={hasControlImg} alt="" />}</div>
                            <div className="player-tour">{shouldPlay && <img src={shouldPlayImg} alt="" />}</div>
                        </div>
                        <div className="played-cards">
                            {[...player?.playCards].map((card, index) => (
                                <div className="card" key={index}>
                                    <div
                                        className="icon icon-card w-100 h-100"
                                        data-card-number="3"
                                        data-card-color="back-heart"
                                    >
                                        <img
                                            className={player.resigned ? 'resigned' : ''}
                                            src={Cards[card]}
                                            alt="Card 11"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* {position() != 'bottom' && (
                    <>
                        <div className="prize victories">
                            <span className="icon icon16">
                                <GoldCupIcon />
                            </span>
                            0
                        </div>
                        <div className="prize defeats">
                            <span className="icon icon24">
                                <DefeatIcon />
                            </span>
                            0
                        </div>
                    </>
                )} */}
            </div>
        </div>
    );
}

export default GameBoardPlayer;
