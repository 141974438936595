import React, { useEffect, useState } from 'react';
import '@components/modals/alert/alert.css';
import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';

type AlertType = 'info' | 'warning' | 'success' | 'error';
export type ActionType = {
    text: string;
    onPress?(): void;
};

export type Callback = () => void;

type Props = {
    title?: string;
    message: string;
    onOk?(): void;
    type: AlertType;
    onConfirm?(): void;
    actions?: ActionType[] | Callback;
    options: {
        confirm: {
            title?: string;
            style?: string;
            show?: boolean;
        };
        cancel: {
            title?: string;
            style?: string;
        };
        className?: {
            message: string;
        };
    };
};

const ErrorIcon: React.FunctionComponent<IconProps> = ({ opened }: IconProps) => {
    const [isOpened, setIsOpened] = useState<boolean | null>(null);

    useEffect(() => {
        if (opened === true) {
            setInterval(() => {
                setIsOpened(opened);
            });
        }
    }, [opened]);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`a-error ${isOpened ? 'toggle' : ''} `}>
            <circle
                className="solid"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <circle
                className="animation"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />

            <line
                className="left"
                fill="none"
                stroke="#000000"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="19.271"
                y1="19.521"
                x2="44.729"
                y2="44.979"
            />
            <line
                className="right"
                fill="none"
                stroke="#000000"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="44.729"
                y1="19.521"
                x2="19.271"
                y2="44.979"
            />
        </svg>
    );
};

const SuccessIcon: React.FunctionComponent<IconProps> = ({ opened }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`a-success ${opened ? 'toggle' : ''} `}>
            <circle
                className="solid"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <circle
                className="animation"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
                fill="none"
                stroke="#000"
                d="M47.91 23.39L26.7 44.61 16.09 34"
            />
        </svg>
    );
};

const WarningIcon: React.FunctionComponent<IconProps> = ({ opened }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`a-warning ${opened ? 'toggle' : ''} `}>
            <circle
                className="solid"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <circle
                className="animation"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <path fill="none" stroke="#000" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" d="M32 15v20" />
            <line
                fill="none"
                stroke="#000"
                strokeWidth="8"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="32"
                y1="46"
                x2="32"
                y2="46"
            />
        </svg>
    );
};

const InfoIcon: React.FunctionComponent<IconProps> = ({ opened }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`a-info ${opened ? 'toggle' : ''} `}>
            <circle
                className="solid"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <circle
                className="animation"
                fill="none"
                strokeLinecap="round"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="32"
                cy="32"
                r="30"
            />
            <path fill="none" stroke="#000" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" d="M32 15v20" />
            <line
                fill="none"
                stroke="#000"
                strokeWidth="8"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="32"
                y1="46"
                x2="32"
                y2="46"
            />
        </svg>
    );
};

type IconTypes = {
    [x in AlertType]: React.FunctionComponent<IconProps>;
};
type IconProps = {
    opened: boolean;
};

function Alert({
    title,
    message,
    onOk = () => {
        /** */
    },
    options,
    type = 'info',
    onConfirm,
    actions,
}: Props): React.ReactElement {
    const { hideModal } = useModal();
    const [opened, setOpened] = useState<boolean>(false);
    const { t } = useTranslation(['attributes']);
    const Icons: IconTypes = {
        success: SuccessIcon,
        info: InfoIcon,
        warning: WarningIcon,
        error: ErrorIcon,
    };
    const handleOkAction = () => {
        if (onOk) {
            onOk();
        }
        hideModal();
    };
    const handleConfirm = () => {
        hideModal();
        if (onConfirm) {
            onConfirm();
        }
    };

    const IconComponent = ({ opened }: IconProps) => {
        const Component = Icons[type as AlertType];
        return <Component opened={opened} />;
    };

    const handleModalOpen = () => {
        setInterval(() => {
            setOpened(true);
        }, 10);
    };

    return (
        <Modal
            show={true}
            onOpen={handleModalOpen}
            showTransition={false}
            showCloseButton={false}
            id="party-modal"
            title={title ?? 'Info'}
            size="modal-sm"
        >
            <div className="form-container m-alert">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className="col-12 text-center icon-container">
                            <IconComponent opened={opened} />
                        </div>
                        <div className="col-12 py-2">
                            <p className={options.className?.message || 'text-center'}>{message}</p>
                        </div>
                        {(!actions || (Array.isArray(actions) && actions.length === 0)) && (
                            <div className="col-12 text-center d-flex justify-content-center gap-1">
                                <button className={`default opaced ${options.cancel?.style} `} onClick={handleOkAction}>
                                    {options.cancel?.title ?? t('cancel', { ns: 'actions' })}
                                </button>
                                {options?.confirm?.show && (
                                    <button
                                        className={`default opaced ${options.confirm?.style} `}
                                        onClick={handleConfirm}
                                    >
                                        {options.confirm?.title}
                                    </button>
                                )}
                            </div>
                        )}
                        {actions && !Array.isArray(actions) && (
                            <div className="col-12 text-center d-flex justify-content-center gap-1">
                                <button className={`default opaced`} onClick={actions}>
                                    {'OK'}
                                </button>
                            </div>
                        )}
                        {Array.isArray(actions) && actions && actions.length > 0 && (
                            <div className="col-12 text-center d-flex justify-content-center gap-1">
                                {(actions as any).map((action: ActionType) => {
                                    <button className="default opaced" onClick={action?.onPress}>
                                        {action?.text}
                                    </button>;
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Alert;
