import { inputChange, inputTouched } from '@redux/reducers/input-reducer';
import React, { ChangeEvent, useEffect, useState, useReducer } from 'react';
import { FormDataState } from '@reducers/form-reducer';
import inputReducer from '@reducers/input-reducer';
import { IconName } from '@components/ui/svg-icon';
import './input.css';
import { useTranslation } from 'react-i18next';
export type InputType = 'text' | 'password' | 'email' | 'tel' | 'button' | 'date' | 'number';

export interface Props {
    id: string;
    blackTheme?: boolean;
    icon?: IconName;
    name: string;
    placeholder?: string;
    type?: InputType;
    label?: string;
    required?: boolean;
    value?: string | number | boolean | undefined;
    children?: React.ReactNode;
    parent?: FormDataState;
    onInput?(value: any): void;
    disabled?: boolean;
    error?: string;
}

export type Ref = HTMLInputElement;
// Notice here we're using the function declaration with the interface Props
const InputField: React.FunctionComponent<Props> = (props) => {
    const { id, name, placeholder, type, label, required, onInput, value, parent, disabled = false } = props;
    //Creating compoent state
    const [error, setError] = useState('');
    const [focused, setFocused] = useState(false);
    const [inputState, dispatch] = useReducer(inputReducer, { value: '' });
    const { value: inputValue } = inputState;
    const [touched, setTouched] = useState<boolean>(false);
    const { t } = useTranslation(['messages']);
    // Initialize the input field with the default value
    useEffect(() => {
        if (value != inputValue) {
            dispatch(inputChange({ val: value ?? '' }));
        }
    }, [value]);

    useEffect(() => {
        onInput ? onInput(inputValue) : {};
    }, [name, inputValue, onInput]);

    useEffect(() => {
        if (parent && parent.inputs[name] && parent.inputs[name].errors !== undefined) {
            const errors = parent.inputs[name].errors;
            if (errors && errors.hasError) {
                setError(errors.first ?? '');
                return;
            }
        }
        setError('');
    }, [parent]);

    useEffect(() => {
        setError(props.error || '');
    }, [props.error]);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(inputChange({ val: event.target.value }));
    };

    const focusHandler = () => {
        setFocused(!focused);
        dispatch(inputTouched(true));
    };

    return (
        <fieldset className="input-field">
            {label && (
                <label htmlFor={id}>
                    {label} {(required === undefined || required) && <span className="form-require">*</span>}
                </label>
            )}

            <input
                type={type ?? 'input'}
                onChange={changeHandler}
                onFocus={focusHandler}
                name={name}
                value={typeof inputValue == 'boolean' ? inputValue + '' : inputValue}
                id={id}
                disabled={disabled}
                placeholder={placeholder ?? ''}
                className={error && touched ? 'invalid' : ''}
                onKeyUp={() => setTouched(true)}
                onInput={changeHandler}
            />
            {error && touched && <strong className="invalid">{t(error)}</strong>}
        </fieldset>
    );
};
InputField.displayName = 'InputField';

export default InputField;
